<template>
  <div id="page-not-found">
    <h1>
      Oops!
    </h1>

    <h3 style="color: #5b5b5b; margin-top: 0">
      that page doesn't exist :(
    </h3>

    <RouterButton id="go-home-btn"
                  to="/"
                  label="take me home!"/>
  </div>
</template>

<script>
  import RouterButton from '@/components/RouterButton';

  export default {
    name: '404',

    components: { RouterButton }
  }
</script>

<style scoped>
  #page-not-found {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: black;
  }

  #go-home-btn {
    margin: 30px auto 0 auto;
    border: solid 1px black;
    color: black;
  }

  #go-home-btn:hover {
    background: rgba(240, 240, 240, 0.45);
    color: black;
  }
</style>